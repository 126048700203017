.table th,
.table td {
	/* padding: 1rem; */
	vertical-align: middle;
	border-top: none;
}

.border-gray {
	padding: 1.25rem;
	border: 1px solid #e6ecf1;
	border-radius: 0.25rem;
}

.expanding-table-row-header {
	background-color: #c8e6c9;
}

.expanding-table-row-body {
	background-color: #c8e6c9;
}

.choose-file-csv {
	font-size: 0 !important;
	cursor: pointer !important;
	position: absolute !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	z-index: 9;
	width: 0;
	height: 0;
	padding: 0 !important;
}

.upload-csv-btn {
	cursor: pointer;
	/* pointer-events: none; */
	margin-left: 0 !important;
}

.header-btns {
	position: static !important;
	width: 100%;
	margin-bottom: 10px;
}

@media all and (min-width: 768px) {
	.header-btns {}

	.header-btns.left {
		left: 3px;
		position: absolute !important;
		top: 0;
		width: initial;
	}

	.header-btns.right {
		right: 3px;
		position: absolute !important;
		top: 0;
		width: initial;
	}
}