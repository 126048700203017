.list-group-item-question {
	/* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.list-group-item-question > p {
	margin-bottom: 0;
}

a.ql-action {
	margin: 0.5rem auto;
	display: block !important;
	margin-left: none !important;
}

/* mcq */
.answer-block-options {
	display: grid;
	flex: 1;
	grid-template-columns: minmax(200px, 3fr) 4rem;
}
.answer-block-options .ql-editor {
	min-height: 2rem;
	height: auto;
	grid-column-start: 1;
}

.answer-block-options .ql-toolbar {
	grid-column-end: 3;
	border: none;
	margin-right: 0;
	padding-right: 0;
	grid-row-end: 2;
}

.question-block-options .ql-editor {
	min-height: 14.75rem;
}
.mcq-block-options .ql-editor {
	min-height: 14.75rem;
}
.subjective-block-options .ql-editor {
	min-height: 9rem;
}
.true-false-block-options .ql-editor {
	min-height: 7rem;
}

.quill {
	flex: 1;
}
