input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
	appearance: textfield;
	margin: 0;
}

/* https://github.com/creativetimofficial/ct-argon-dashboard-pro/issues/21 */
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.carousel-item>img {
	width: 100%;
	height: 100%;
}

.cursor-pointer {
	cursor: pointer !important;
}

.time-keeper-modal {
	max-width: 260px;
}

.ql-tooltip {
	/* width: 90vw; */
	/* left: 0px !important; */
	left: auto !important;
	flex-wrap: wrap !important;
	width: auto;
	top: 85px !important;
	position: fixed !important;
	right: 5px !important;
	width: 332px !important;
}

.ql_tooltip:hover {
	/* display: grid !important; */
}

.mathquill4quill-mathquill-input {
	min-width: 100% !important;
	min-height: 100px !important;
	max-height: 150px !important;
	font-size: 1rem !important;
}

/* shows time instead of broken icon */
.rdtPicker .rdtTimeToggle:before {
	visibility: hidden !important;
}

.rdtPicker .rdtTimeToggle {
	visibility: visible;
}

/* changes selected date color */
.rdtDay.rdtToday.rdtActive,
.rdtDay.rdtActive,
.rdtDay.rdtActive:hover,
.rdtDay.start-date,
.rdtDay.end-date,
.rdtDay.middle-date,
.rdtDay.start-date:hover,
.rdtDay.end-date:hover,
.rdtDay.middle-date:hover {
	background-color: #5e72e4 !important;
}

.page-item.acitve a {
	background-color: #ccd2f5;
}

.page-item a:hover {
	background-color: #a5b0ec !important;
}

.page-item .page-link {
	height: 42px !important;
	width: 42px !important;
}
.error {
	color: red;
	display: block;
	margin-top: 5px;
}

.pagination-wrap .pagination{
justify-content: flex-end;
}