.red {
	border: 1px solid #f5365c !important;
	border-left: 0.4rem solid #f5365c !important;
}

.green {
	/* cursor: pointer !important; */
	border: 1px solid #2dce89 !important;
	border-left: 0.4rem solid #2dce89 !important;
}
/* .green:hover {
	transform: scale(1.01);
} */

.blue {
	border: 1px solid #11cdef !important;

	border-left: 0.4rem solid #11cdef !important;
}

.primary {
	border: 1px solid #5e72e4 !important;
	border-left: 0.4rem solid #5e72e4 !important;
}

.orange {
	border: 1px solid rgb(244, 215, 138) !important;
	border-left: 0.4rem solid rgb(244, 215, 138) !important;
}
