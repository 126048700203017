.radio {
	opacity: 0;
	position: fixed;
	width: 0;
}

.label:hover {
	cursor: pointer !important;
}

.label {
	display: inline-block;
	/* background-color: #fff; */

	/* padding: 10px 10px; */
	margin: 10px;
	font-family: sans-serif, Arial;
	font-size: 16px;

	margin: 0;
	/* box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02); */
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	/* border-radius: 3rem; */
	/* color: #363636; */
	text-align: center;
	/* transition: all 0.1s; */
	background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
	border: 0;

	/* transition: box-shadow 0.15s ease; */
}

.radio:checked + label {
	background-color: #5e72e4;
	color: white;
}

/* .radio:disabled + label {
	background-color: #e9ecef;
	color: #525f7f;
} */
